import {
  TableSearchBar,
  Article,
  Loader,
  Page,
  Section,
  Button,
  Checkbox,
  Select,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import { ExperienceWithTicketsAndHost } from '~/generated/models/ExperienceWithTicketsAndHost';
import { ExperiencesListTable } from '~/components';
import { ExperienceStatus } from '~/generated/models/ExperienceStatus.ts';
import { ExperienceType } from '~/generated/models/ExperienceType.ts';

type ExperiencesPageProps = {
  experiences: ExperienceWithTicketsAndHost[];
  nextPage: () => Promise<void>;
  hasNextPage: boolean;
  callingMore: boolean;
};

type Filters = {
  searchTerm: string;
  privateOnly: boolean;
  unpaidOnly: boolean;
  upcomingOnly: boolean;
  status: ExperienceStatus | null;
  type: ExperienceType | null;
};

const filterExperiences = (
  experiences: ExperienceWithTicketsAndHost[],
  filters: Filters,
) => {
  let filtered = [...experiences];

  if (filters.searchTerm) {
    filtered = filtered.filter((e) =>
      JSON.stringify(e)
        .toLowerCase()
        .includes(filters.searchTerm.toLowerCase()),
    );
  }

  if (filters.status) {
    filtered = filtered.filter((e) => e.status === filters.status);
  }

  if (filters.privateOnly) {
    filtered = filtered.filter((e) => !e.isPublic);
  }

  if (filters.unpaidOnly) {
    filtered = filtered
      .filter((e) => !e.datePaid)
      .filter((e) => e.tiers.some((t) => t.price > 0))
      .filter((e) => e.status === ExperienceStatus.Published)
      .filter((e) => DateUtils.isPast(e.startDate));
  }

  if (filters.upcomingOnly) {
    filtered = filtered
      .filter((e) => e.status === ExperienceStatus.Published)
      .filter((e) => DateUtils.isFuture(e.startDate));
  }

  if (filters.type) {
    filtered = filtered.filter((e) => e.type === filters.type);
  }

  return filtered;
};

export default function ExperiencesPage({
  experiences,
  nextPage,
  hasNextPage,
  callingMore,
}: ExperiencesPageProps) {
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  // const [aiOnly, setAIOnly] = React.useState<boolean>(false);
  const [privateOnly, setPrivateOnly] = React.useState<boolean>(false);
  const [unpaidOnly, setUnpaidOnly] = React.useState<boolean>(false);
  const [upcomingOnly, setUpcomingOnly] = React.useState<boolean>(true);
  const [status, setStatus] = React.useState<ExperienceStatus | null>(null);
  const [type, setType] = React.useState<ExperienceType | null>(
    ExperienceType.OneTime,
  );

  const filtered = filterExperiences(experiences, {
    searchTerm,
    status,
    privateOnly,
    unpaidOnly,
    upcomingOnly,
    type,
  });

  return (
    <Page>
      <Section>
        <Article>
          <div className="flex gap-5 flex-col w-full">
            <h2 className="justify-center text-black">All Experiences</h2>

            <TableSearchBar
              onSearch={setSearchTerm}
              actionItems={[
                <div
                  className="flex flex-row items-center justify-center w-full"
                  key="private-only"
                >
                  Private
                  <Checkbox
                    className="ml-1"
                    onClick={() => setPrivateOnly(!privateOnly)}
                    defaultChecked={privateOnly}
                  />
                </div>,
                <div
                  className="flex flex-row items-center justify-center w-full"
                  key="upcoming"
                >
                  Upcoming
                  <Checkbox
                    className="ml-1"
                    onClick={() => {
                      setUpcomingOnly(!upcomingOnly);
                    }}
                    defaultChecked={upcomingOnly}
                  />
                </div>,
                <div
                  className="flex flex-row items-center justify-center w-full"
                  key="unpaid-only"
                >
                  Unpaid
                  <Checkbox
                    className="ml-1"
                    onClick={() => {
                      setUnpaidOnly(!unpaidOnly);
                    }}
                    defaultChecked={unpaidOnly}
                  />
                </div>,
                <Select
                  key="experience-type"
                  className="w-full"
                  value={type ?? 'All'}
                  onChange={(e) => {
                    if (e.target.value === 'All') {
                      setType(null);
                    } else {
                      setType(e.target.value as ExperienceType);
                    }
                  }}
                >
                  <option value="All">All</option>
                  <option value={ExperienceType.OneTime}>
                    {ExperienceType.OneTime}
                  </option>
                  <option value={ExperienceType.RunClub}>
                    {ExperienceType.RunClub}
                  </option>
                </Select>,
                <Select
                  key="experience-status"
                  className="w-full"
                  value={status ?? 'All'}
                  onChange={(e) => {
                    if (e.target.value === 'All') {
                      setStatus(null);
                    } else {
                      setStatus(e.target.value as ExperienceStatus);
                    }
                  }}
                >
                  <option value="All">All</option>
                  <option value={ExperienceStatus.Published}>
                    {ExperienceStatus.Published}
                  </option>
                  <option value={ExperienceStatus.Draft}>
                    {ExperienceStatus.Draft}
                  </option>
                </Select>,
              ]}
            />
            <ExperiencesListTable experiences={filtered} />
            <Loader horizontal loading={callingMore} />
            {hasNextPage && (
              <Button
                disabled={callingMore}
                color="secondary"
                className="w-full flex justify-center"
                onClick={nextPage}
              >
                Fetch More
              </Button>
            )}
          </div>
        </Article>
      </Section>
    </Page>
  );
}
