import { Circle as GCircle, CircleProps } from '@react-google-maps/api';
import { pinColors, PinColors } from './colors.ts';

const options = {
  strokeColor: 'transparent',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#D8607A',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
};

type Props = CircleProps & {
  color?: PinColors;
};

function Circle({ color, ...props }: Props) {
  const { fillColor } = color ? pinColors[color] : {};

  return (
    <GCircle
      radius={750}
      options={{
        ...options,
        fillColor,
      }}
      {...props}
    />
  );
}

Circle.displayName = 'Circle';

export default Circle;
