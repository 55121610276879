/* eslint-disable */


import { useHttp } from '@cg/frontend-core';

import { runQuery, RunQueryArgs } from './BackgroundAnalytics.client';

export const runQueryHook = (immediateCall: boolean = true, initArgs?: RunQueryArgs) => {
  return useHttp(runQuery, { immediateCall, initArgs })
};
