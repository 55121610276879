import React, {
  ChangeEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FileInput, Modal, Spinner } from 'flowbite-react';
import ReactCrop, { Crop } from 'react-image-crop';
import classNames from 'classnames';
import {
  ExperienceId,
  ExperienceSeriesId,
  HostId,
  VenueId,
} from '@cg/common/src/ids';
import { Logger } from '@cg/frontend-core';
import { ImageContentType } from '~/generated/models/ImageContentType';
import { Loader } from '../loader';
import { toast, byteToMb } from '../../utils';
import { uploadPhotoHook as userPhotoHook } from '~/generated/clients/private/self/PrivateSelf.hooks';
import { uploadPhotoHook as hostPhotoHook } from '~/generated/clients/playground/hosts/PlaygroundHosts.hooks';
import { uploadPhotoHook as experiencePhotoHook } from '~/generated/clients/playground/hosts/experiences/PlaygroundHostsExperiences.hooks';
import { uploadPhotoHook as experienceSeriesPhotoHook } from '~/generated/clients/playground/hosts/series/PlaygroundHostsSeries.hooks';
import { uploadPhotoHook as hostBackgroundPhotoHook } from '~/generated/clients/background/hosts/BackgroundHosts.hooks';
import { uploadPhotoHook as venuePhotoHook } from '~/generated/clients/background/venues/BackgroundVenues.hooks';
import { PreSignedUrlResponse } from '~/generated/models/PreSignedUrlResponse';
import 'react-image-crop/dist/ReactCrop.css';
import { DesktopScreen, MobileScreen } from '../views';
import { Button } from '../buttons';
import Img from '../img';
import { image2WebP } from '../../utils/images.ts';
import { HostPhotoType } from '~/generated/models/HostPhotoType.ts';

const logger = new Logger('FileUpload');

type FileUploadProps = {
  background?: boolean;
  className?: string;
  children?:
    | React.ReactNode
    | ((props: { openFileSelector: () => void }) => React.ReactElement);
  onUploading: () => void;
  onUploaded: (response: PreSignedUrlResponse) => void;
  onUploadFailed: () => void;
  type:
    | 'User'
    | 'Host'
    | 'Experience'
    | 'Venue'
    | 'HostBanner'
    | 'ExperienceSeries';
  ids?: {
    experienceSeriesId?: ExperienceSeriesId;
    experienceId?: ExperienceId;
    hostId?: HostId;
    venueId?: VenueId;
  };
  maxSize: number;
  minWidth: number;
  minHeight: number;
  circularCrop?: boolean;
};

const acceptedImageTypes: string[] = Object.values(ImageContentType);
acceptedImageTypes.push('image/heic');
const getImageContentType = (type: string): ImageContentType | undefined => {
  return Object.values(ImageContentType).includes(type as ImageContentType)
    ? (type as ImageContentType)
    : undefined;
};

const toCroppedImage = (
  ref: RefObject<HTMLImageElement>,
  crop: Crop,
): Promise<Blob> => {
  if (!ref.current) {
    return Promise.reject(new Error('Could not find image ref'));
  }

  const image = ref.current;
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return Promise.reject(new Error('Could not create canvas context'));
  }

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  return new Promise((resolve, reject) => {
    const imageType = image.src.split(';')[0].split(':')[1];
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error('Could not create blob'));
      }
    }, imageType);
  });
};

function FileUpload({
  background,
  className,
  children,
  minWidth,
  minHeight,
  maxSize,
  type,
  ids,
  onUploaded,
  onUploadFailed,
  onUploading,
  circularCrop,
}: FileUploadProps) {
  const [preSignedUrl, setPreSignedUrl] = useState<PreSignedUrlResponse | null>(
    null,
  );
  const { data: userPreSignedUrl, call: getUserPreSignedUrl } =
    userPhotoHook(false);
  const { data: hostPreSignedUrl, call: getHostPreSignedUrl } = background
    ? hostBackgroundPhotoHook(false)
    : hostPhotoHook(false);
  const { data: experiencePreSignedUrl, call: getExperiencePreSignedUrl } =
    experiencePhotoHook(false);
  const {
    data: experienceSeriesPreSignedUrl,
    call: getExperienceSeriesPreSignedUrl,
  } = experienceSeriesPhotoHook(false);
  const { data: venuePreSignedUrl, call: getVenuePreSignedUrl } =
    venuePhotoHook(false);

  const fileRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const [croppingError, setCroppingError] = useState<string | null>();
  const [image, setImage] = useState<HTMLImageElement>();
  const [croppedImage, setCroppedImage] = useState<Blob>();
  const [crop, setCrop] = useState<Crop>();

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  /**
   * Handles the initial file upload to display for cropping
   * @param event
   */
  const onFileInput = async (event: ChangeEvent<HTMLInputElement>) => {
    setCroppingError(null);
    const onError = (message: string) => {
      toast.error(message);
      setOpenModal(false);
      setLoading(false);
      setCroppingError(null);
      if (fileRef.current) {
        fileRef.current.value = '';
      }
    };

    let inputFile = event.target.files ? event.target.files[0] : null;
    if (!inputFile) {
      onError("It's not you, it's us. Something went wrong.");
      return;
    }

    setOpenModal(true);
    setLoading(true);

    // convert to webp
    try {
      inputFile = await image2WebP(inputFile, inputFile.type);
    } catch (e) {
      logger.error('Failed to convert image to webp', e);
      onError("It's not you, it's us. Something went wrong.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const img = new Image();
      img.src = e.target?.result as string;

      const onImageLoad = () => {
        img.removeEventListener('load', onImageLoad);

        if (img.naturalWidth < minWidth || img.naturalHeight < minHeight) {
          onError(`Image must be at least ${minWidth}x${minHeight}`);
          return;
        }

        setCrop({
          x: 0,
          width: minWidth,
          y: 0,
          height: minHeight,
          unit: 'px',
        });
        setImage(img);
        setLoading(false);
      };
      img.addEventListener('load', onImageLoad);
    };
    reader.readAsDataURL(inputFile);
  };

  // Upload the photo via first creating a presigned URL then uploading the photo to AWS directly
  // The AWS upload is handled in the useEffect
  const uploadPhoto = async () => {
    setCroppingError(null);
    if (!imageRef || !crop?.width || !crop?.height) {
      logger.error('No imageRef or crop width/height');
      setCroppingError("It's not you, it's us. Something went wrong.");
      return;
    }

    const blob = await toCroppedImage(imageRef, crop);
    setCroppedImage(blob);
    const size = Math.round(byteToMb(blob.size) * 10) / 10;
    if (size > maxSize) {
      setCroppingError(
        `Image must be less than ${maxSize}mb - uploaded image was ${size}mb`,
      );
      return;
    }

    const contentType = getImageContentType(blob.type);
    if (!contentType) {
      setCroppingError('Could not determine image type');
      return;
    }

    const body = {
      contentLength: blob.size,
      contentType,
    };

    onUploading();
    setIsUploading(true);
    if (type === 'User') {
      await getUserPreSignedUrl({ body });
      return;
    }

    if (type === 'Host' && ids?.hostId) {
      await getHostPreSignedUrl({
        ids: {
          hostId: ids?.hostId,
        },
        body: {
          ...body,
          type: HostPhotoType.Profile,
        },
      });
      return;
    }

    if (type === 'HostBanner' && ids?.hostId) {
      await getHostPreSignedUrl({
        ids: {
          hostId: ids?.hostId,
        },
        body: {
          ...body,
          type: HostPhotoType.Banner,
        },
      });
      return;
    }
    if (type === 'Experience' && ids?.experienceId && ids?.hostId) {
      await getExperiencePreSignedUrl({
        ids: {
          experienceId: ids.experienceId,
          hostId: ids.hostId,
        },
        body,
      });
      return;
    }
    if (type === 'ExperienceSeries' && ids?.experienceSeriesId && ids?.hostId) {
      await getExperienceSeriesPreSignedUrl({
        ids: {
          experienceSeriesId: ids.experienceSeriesId,
          hostId: ids.hostId,
        },
        body,
      });
      return;
    }

    if (type === 'Venue' && ids?.venueId) {
      await getVenuePreSignedUrl({
        ids: {
          venueId: ids.venueId,
        },
        body,
      });
      return;
    }

    throw new Error(`Unknown type: ${type}`);
  };

  useEffect(() => {
    if (!croppedImage || !preSignedUrl) {
      return;
    }

    const uploadToAws = async (blob: Blob) => {
      logger.debug('Uploading to AWS as file-type:', blob.type);
      setCroppingError(null);

      const response = await fetch(preSignedUrl.preSignedUrl, {
        method: 'PUT',
        body: blob,
        headers: {
          'content-type': blob.type,
          'cache-control': 'public, max-age=31536000',
        },
      });

      if (!response.ok) {
        setCroppingError(response.statusText);
        onUploadFailed();
        setIsUploading(false);
        return;
      }

      setPreSignedUrl(null);
      setOpenModal(false);
      onUploaded(preSignedUrl);
      setIsUploading(false);
    };

    uploadToAws(croppedImage);
  }, [croppedImage, preSignedUrl]);

  useEffect(() => {
    if (userPreSignedUrl) {
      setPreSignedUrl(userPreSignedUrl);
      return;
    }
    if (hostPreSignedUrl) {
      setPreSignedUrl(hostPreSignedUrl);
      return;
    }
    if (experiencePreSignedUrl) {
      setPreSignedUrl(experiencePreSignedUrl);
    }
    if (venuePreSignedUrl) {
      setPreSignedUrl(venuePreSignedUrl);
    }
    if (experienceSeriesPreSignedUrl) {
      setPreSignedUrl(experienceSeriesPreSignedUrl);
    }
  }, [
    experiencePreSignedUrl,
    userPreSignedUrl,
    hostPreSignedUrl,
    venuePreSignedUrl,
    experienceSeriesPreSignedUrl,
  ]);

  const isChildFunction = typeof children === 'function';
  const openFileSelector = () => {
    fileRef.current?.click();
  };
  const aspectRatio = type === 'HostBanner' ? 4 / 3 : 1;

  return (
    <>
      <div
        className={classNames(className, {
          'cursor-default': isChildFunction,
        })}
        onClick={() => {
          if (!isChildFunction) {
            openFileSelector();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            fileRef.current?.click();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <FileInput
          style={{ display: 'none' }}
          onChange={onFileInput}
          ref={fileRef}
          accept={acceptedImageTypes.join(',')}
        />
        {isChildFunction ? children({ openFileSelector }) : children}
      </div>
      <DesktopScreen>
        <Modal
          show={openModal}
          onClose={() => setOpenModal(false)}
          position="center"
          size="fit"
          className="z-[1000] px-4"
          theme={{
            content: {
              base: 'bg-black-800/50 w-fit object-contain',
            },
          }}
        >
          <Modal.Header>Crop Your Photo</Modal.Header>
          <Modal.Body>
            <div className="space-y-6 min-w-72 items-center justify-center flex">
              <Loader horizontal loading={loading}>
                <ReactCrop
                  ruleOfThirds
                  crop={crop}
                  minWidth={minWidth}
                  minHeight={minHeight}
                  onChange={(c) => setCrop(c)}
                  circularCrop={circularCrop}
                  className="relative"
                  aspect={aspectRatio}
                >
                  {isUploading && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <Spinner size="xl" />
                    </div>
                  )}
                  {image && (
                    <Img
                      src={image.src}
                      alt="cropper"
                      ref={imageRef}
                      className={classNames({
                        'pointer-events-none opacity-30': isUploading,
                      })}
                    />
                  )}
                </ReactCrop>
              </Loader>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-between items-center w-full">
              <div className="text-failure">{croppingError}</div>
              <Button
                color="primary"
                onClick={uploadPhoto}
                icon={isUploading && <Spinner className="mr-2" />}
                disabled={isUploading}
              >
                Upload
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </DesktopScreen>
      <MobileScreen>
        <Modal
          show={openModal}
          onClose={() => setOpenModal(false)}
          position="top"
          size="sm"
          className="z-[1000] p-4"
          theme={{
            content: {
              base: 'bg-black-800/50 w-fit object-contain',
            },
          }}
        >
          <Modal.Header>Crop Your Photo</Modal.Header>
          <Modal.Body>
            <div className="space-y-6 min-w-72 max-h-[calc(50dvh)] items-center justify-center flex">
              <Loader horizontal loading={loading}>
                <ReactCrop
                  ruleOfThirds
                  crop={crop}
                  minWidth={minWidth}
                  minHeight={minHeight}
                  onChange={(c) => setCrop(c)}
                  circularCrop={circularCrop}
                  className="relative"
                  aspect={aspectRatio}
                >
                  {isUploading && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <Spinner size="xl" />
                    </div>
                  )}
                  {image && (
                    <Img
                      src={image.src}
                      alt="cropper"
                      ref={imageRef}
                      className={classNames({
                        'pointer-events-none opacity-30': isUploading,
                      })}
                    />
                  )}
                </ReactCrop>
              </Loader>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-between items-center w-full">
              <div className="text-failure">{croppingError}</div>
              <Button
                color="primary"
                onClick={uploadPhoto}
                icon={isUploading && <Spinner className="mr-2" />}
                disabled={isUploading}
              >
                Upload
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </MobileScreen>
    </>
  );
}

export default FileUpload;
