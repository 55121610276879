/* eslint-disable */


import { http, HttpResult } from '@cg/frontend-core';

import { GMVSale } from '../../../../models/GMVSale';
import { TicketCount } from '../../../../models/TicketCount';
import { GraphData } from '../../../../models/GraphData';

export type CountSalesArgs = {
    verifiedUser?: boolean;
}

/**
 * @summary Returns a list of Users
 */
export const countSales = (args: CountSalesArgs): Promise<HttpResult<GMVSale>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/aggregates/sales/count', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type CountTicketsArgs = {
    verifiedUser?: boolean;
}

/**
 * @summary Returns a list of Users
 */
export const countTickets = (args: CountTicketsArgs): Promise<HttpResult<TicketCount>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/aggregates/tickets/count', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type GraphSalesArgs = {
    verifiedUser?: boolean;
    filters?: {
        ignoreCGHost?: boolean;
        lastDays?: number;
    };
}

/**
 * @param {GraphSalesArgs} args
 */
export const graphSales = (args: GraphSalesArgs): Promise<HttpResult<GraphData>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/aggregates/sales/graph', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type GraphTicketsSoldArgs = {
    verifiedUser?: boolean;
    filters?: {
        ignoreCGHost?: boolean;
        lastDays?: number;
    };
}

/**
 * @param {GraphTicketsSoldArgs} args
 */
export const graphTicketsSold = (args: GraphTicketsSoldArgs): Promise<HttpResult<GraphData>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/aggregates/tickets/graph', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
