import { getDevice } from '@cg/common/src/core';

const getEnv = (key: string): string => {
  if (getDevice() === 'Mobile') {
    const match = process.env[key];
    if (match) {
      return match;
    }

    throw new Error(`Environment variable ${key} is not defined`);
  }

  // @ts-ignore
  const match = import.meta.env[key];
  if (match) {
    return match as string;
  }

  throw new Error(`Environment variable ${key} is not defined`);
};

export const getRequiredEnv = (key: string): string => {
  return getEnv(key);
};

export const getOptionalEnv = (key: string): string => {
  try {
    return getEnv(key);
  } catch {
    return '';
  }
};
