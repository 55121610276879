import {
  TableSearchBar,
  Page,
  Section,
  Article,
  Loader,
  UserAvatar,
  Table,
  Button,
  Href,
} from '@cg/module-frontend/src/components';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { EmailIcon, ExportIcon } from '@cg/module-frontend/src/icons';
import { useState } from 'react';
import { downloadCsv } from '@cg/module-frontend/src/utils';
import {
  TableRowItem,
  TableSortDirection,
} from '@cg/module-frontend/src/components/table';
import {
  downloadUsersHook,
  listUsersHook,
} from '~/generated/clients/background/users/BackgroundUsers.hooks';
import { UserStatus } from '~/generated/models/UserStatus';
import { UserWithTicketCount } from '~/generated/models/UserWithTicketCount';

type Filters = {
  searchTerm: string;
  lastLoggedSort: TableSortDirection;
  ticketCountSort: TableSortDirection;
  loginCount: TableSortDirection;
};

type OptionalCsvMap = (
  data: Partial<UserWithTicketCount>,
) => Record<string, unknown>;

const filterUsers = (users: UserWithTicketCount[], filters: Filters) => {
  let filtered = [...users];

  if (filters.searchTerm) {
    filtered = filtered.filter((user) =>
      JSON.stringify(user)
        .toLowerCase()
        .includes(filters.searchTerm.toLowerCase()),
    );
  }

  if (filters.lastLoggedSort) {
    filtered = filtered.sort((a, b) => {
      const aDate = DateUtils.diffFromNow(a.lastLoggedIn);
      const bDate = DateUtils.diffFromNow(b.lastLoggedIn);
      return filters.lastLoggedSort === 'asc' ? aDate - bDate : bDate - aDate;
    });
  }

  if (filters.ticketCountSort) {
    filtered = filtered.sort((a, b) => {
      return filters.ticketCountSort === 'asc'
        ? a.ticketCount - b.ticketCount
        : b.ticketCount - a.ticketCount;
    });
  }

  if (filters.loginCount) {
    filtered = filtered.sort((a, b) => {
      return filters.loginCount === 'asc'
        ? a.loginCount - b.loginCount
        : b.loginCount - a.loginCount;
    });
  }

  return filtered;
};

function AllUsersPage() {
  const navigate = useNavigate();
  const { calling: downloadingUsers, call: downloadUsers } =
    downloadUsersHook(false);
  const {
    data: users,
    calling: fetchingUsers,
    nextPage: fetchNextPageUsers,
    callingMore,
  } = listUsersHook(true, {
    filters: {
      status: UserStatus.Active,
      pageSize: 100,
    },
  });
  const hasNextPage = users?.metadata?.nextPageToken !== null;

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [lastLoggedSort, setLastLoggedSort] =
    useState<TableSortDirection>('desc');
  const [loginCount, setLoginCount] = useState<TableSortDirection>(null);
  const [ticketCountSort, setTicketCountSort] =
    useState<TableSortDirection>('desc');
  const filters = { searchTerm, lastLoggedSort, ticketCountSort, loginCount };

  const exportToCsv = (additionalMap?: OptionalCsvMap) => {
    const data = filterUsers(users?.result ?? [], filters)
      .map((user) => {
        return {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          ticketCount: user.ticketCount,
          gender: user.gender,
          dob: DateUtils.dater(user.dob).format('MMMM DD, YYYY'),
          lastLoggedDate: DateUtils.dater(user.lastLoggedIn).format(
            'MMMM DD, YYYY',
          ),
          lastLoggedDays: DateUtils.dater(user.lastLoggedIn).diff(
            DateUtils.dayjs(),
            'days',
          ),
        };
      })
      .map((user) => {
        if (additionalMap) {
          return additionalMap(user);
        }
        return user;
      });

    downloadCsv(data);
  };

  const headers = [
    'Name',
    'Phone',
    {
      label: 'Tickets',
      key: 'tickets',
      filter: setTicketCountSort,
    },
    'Gender',
    'Diet',
    'DoB',
    {
      label: 'Last Seen',
      key: 'lastLoggedIn',
      filter: setLastLoggedSort,
    },
    {
      label: 'Logging Count',
      key: 'loginCount',
      filter: setLoginCount,
    },
  ];
  const rows: TableRowItem[] = filterUsers(users?.result ?? [], filters).map(
    (user) => {
      return {
        key: user.id.getValue(),
        className: 'bg-white border-grey-darker cursor-pointer',
        onClick: () => navigate(`/users/${user.id.getValue()}`),
        cells: [
          <div className="flex flex-row items-center">
            <UserAvatar user={user} iconSize="md" />
            <span className="ml-2 whitespace-nowrap">
              {user.firstName} {user.lastName}
            </span>
          </div>,
          <Href
            className="underline text-primary hover:text-primary-darker visited:text-primary"
            to={`tel:${user.phoneNumber}`}
          >
            {user.phoneNumber}
          </Href>,
          user.ticketCount,
          user.gender,
          user.diet.join(', '),
          DateUtils.dater(user.dob).format('MMMM DD, YYYY'),
          DateUtils.dater(user.lastLoggedIn).fromNow(),
          user.loginCount,
        ],
      };
    },
  );

  const exportAll = async () => {
    const response = await downloadUsers({});
    if (response.succeeded) {
      const payload = response.payload.map((user) => {
        return {
          ...user,
          id: user.id.getValue(),
        };
      });
      downloadCsv(payload);
    }
  };

  return (
    <Page>
      <Section>
        <Article>
          <div className="flex gap-5 flex-col w-full">
            <h2 className="justify-center text-black">All Users</h2>

            <Loader horizontal loading={fetchingUsers && !callingMore}>
              <TableSearchBar
                onSearch={setSearchTerm}
                actionItems={[
                  {
                    label: 'Export All',
                    onClick: exportAll,
                    loading: downloadingUsers,
                    disabled: downloadingUsers,
                    icon: <ExportIcon className="size-5" />,
                  },
                  {
                    label: 'Export',
                    onClick: () => exportToCsv(),
                    icon: <ExportIcon className="size-5" />,
                  },
                ]}
                moreActionItems={[
                  {
                    label: 'Export Emails',
                    onClick: () =>
                      exportToCsv((u) => ({
                        email: u.email,
                        firstName: u.firstName,
                        lastName: u.lastName,
                      })),
                    icon: <EmailIcon />,
                  },
                ]}
              />

              <Table headers={headers} rows={rows} />

              <Loader horizontal loading={callingMore} />
              {hasNextPage && (
                <Button
                  disabled={callingMore}
                  color="secondary"
                  className="w-full flex justify-center"
                  onClick={fetchNextPageUsers}
                >
                  Fetch More
                </Button>
              )}
            </Loader>
          </div>
        </Article>
      </Section>
    </Page>
  );
}

export default AllUsersPage;
