/* eslint-disable */


import { http, HttpResult } from '@cg/frontend-core';

import { ExperienceCount } from '../../../../models/ExperienceCount';
import { GraphData } from '../../../../models/GraphData';

export type CountExperiencesArgs = {
    verifiedUser?: boolean;
}

/**
 * @summary The count of experiences
 */
export const countExperiences = (args: CountExperiencesArgs): Promise<HttpResult<ExperienceCount>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/aggregates/experiences/count', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type GraphExperiencesArgs = {
    verifiedUser?: boolean;
    filters?: {
        ignoreCGHost?: boolean;
        lastDays?: number;
    };
}

/**
 * @param {GraphExperiencesArgs} args
 */
export const graphExperiences = (args: GraphExperiencesArgs): Promise<HttpResult<GraphData>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/aggregates/experiences/graph', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
