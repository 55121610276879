export type PinColors = 'red' | 'yellow';

type PinColor = {
  fillColor: string;
  strokeColor: string;
};
export const pinColors: Record<PinColors, PinColor> = {
  red: {
    fillColor: '#D8607A',
    strokeColor: '#bf546b',
  },
  yellow: {
    fillColor: '#FFC107',
    strokeColor: '#ddb200',
  },
};
