/* eslint-disable */


import { http, HttpResult } from '@cg/frontend-core';

import { AnalyticsQueryRequest } from '../../../models/AnalyticsQueryRequest';
import { AnalyticsQueryResponse } from '../../../models/AnalyticsQueryResponse';

export type RunQueryArgs = {
    verifiedUser?: boolean;
      body: AnalyticsQueryRequest;
}

/**
 * <p>Internal API for Analytics</p>
 * @summary Performs a query
 * @param {RunQueryArgs} args
 */
export const runQuery = (args: RunQueryArgs): Promise<HttpResult<AnalyticsQueryResponse>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/backgronud/analytics', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
