import { getDevice } from '@cg/common/src/core';
import { getOptionalEnv } from './env';

const isBrowser = getDevice() === 'Browser';

// Safe `isLocal` check (works for Web & Mobile)
let isLocal;
if (isBrowser) {
  isLocal =
    typeof window !== 'undefined' &&
    window.location.host.startsWith('localhost');
} else {
  isLocal = process.env.NODE_ENV === 'development';
}

// Safe host determination (ensure `window` exists before accessing)
let host;
if (isBrowser) {
  if (isLocal) {
    host = 'http://localhost';
  } else {
    host = `https://${window.location.host}`;
  }
} else {
  host = 'https://joincommonground.com';
}

// Safe API host determination
let apiHost;
if (isBrowser) {
  if (isLocal) {
    apiHost = 'http://localhost:3000';
  } else {
    apiHost = `https://api.${window.location.host
      .replace('www.', '')
      .replace('playground.', '')
      .replace('background.', '')}`;
  }
} else {
  apiHost = 'https://api.joincommonground.com';
}

// Safe domain determination
const domain = isBrowser ? window.location.hostname : 'joincommonground.com';
const subDomain = domain.includes('stage') ? 'stage.' : '';

let environment = domain.includes('stage') ? 'stage' : 'prod';
if (isLocal) {
  environment = 'local';
}

export const boxConfig = {
  environment,
  host: getOptionalEnv('VITE_HOST') || host,
  apiHost:
    getOptionalEnv('VITE_USE_REALM') === 'stage'
      ? 'https://api.stage.joincommonground.com'
      : apiHost,
  isLocal,
  domain,
  isBrowser,
  isCG:
    [
      'joincommonground.com',
      'stage.joincommonground.com',
      'localhost:8080',
    ].includes(window?.location?.host) || !isBrowser,
  isPG: [
    'playground.joincommonground.com',
    'playground.stage.joincommonground.com',
    'localhost:8090',
  ].includes(window?.location?.host),
  isBG: [
    'background.joincommonground.com',
    'background.stage.joincommonground.com',
    'localhost:8000',
  ].includes(window?.location?.host),
  hosts: {
    commonGround: isLocal
      ? 'localhost:8080'
      : `${subDomain}joincommonground.com`,
    playGround: isLocal
      ? 'localhost:8090'
      : `playground.${subDomain}joincommonground.com`,
    backGround: isLocal
      ? 'localhost:8000'
      : `background.${subDomain}joincommonground.com`,
  },
  baseUrls: {
    commonGround: isLocal
      ? 'http://localhost:8080'
      : `https://${subDomain}joincommonground.com`,
    playGround: isLocal
      ? 'http://localhost:8090'
      : `https://playground.${subDomain}joincommonground.com`,
    backGround: isLocal
      ? 'http://localhost:8000'
      : `https://background.${subDomain}joincommonground.com`,
  },
};
export type BoxConfig = typeof boxConfig;

const config = {
  box: boxConfig,
};
export type Config = typeof config;
export default config;
