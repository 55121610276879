/* eslint-disable */


import { useHttp } from '@cg/frontend-core';

import { createFeatureFlag, CreateFeatureFlagArgs, getFeatureFlag, GetFeatureFlagArgs, listFeatureFlags, ListFeatureFlagsArgs, updateFeatureFlag, UpdateFeatureFlagArgs } from './BackgroundFeatures.client';

export const createFeatureFlagHook = (immediateCall: boolean = true, initArgs?: CreateFeatureFlagArgs) => {
  return useHttp(createFeatureFlag, { immediateCall, initArgs })
};
export const getFeatureFlagHook = (immediateCall: boolean = true, initArgs?: GetFeatureFlagArgs) => {
  return useHttp(getFeatureFlag, { immediateCall, initArgs })
};
export const listFeatureFlagsHook = (immediateCall: boolean = true, initArgs?: ListFeatureFlagsArgs) => {
  return useHttp(listFeatureFlags, { immediateCall, initArgs })
};
export const updateFeatureFlagHook = (immediateCall: boolean = true, initArgs?: UpdateFeatureFlagArgs) => {
  return useHttp(updateFeatureFlag, { immediateCall, initArgs })
};
