import { useEffect } from 'react';
import { Series } from '@cg/module-frontend/src/components/chartboard/charts/LineChart.tsx';
import {
  ChartBoard,
  ChartCard,
  ChartCards,
  ChartGraph,
  LineChart,
  Article,
  Loader,
  Page,
  Section,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import * as DateUtils from '@cg/common/src/utils/DateUtils.ts';
import {
  countExperiencesHook,
  graphExperiencesHook,
} from '~/generated/clients/background/aggregates/experiences/BackgroundAggregatesExperiences.hooks.ts';
import { GraphData } from '~/generated/models/GraphData.ts';

export default function ExperiencesDashboard() {
  const { data: experiencesCount, calling: fetchingExperiencesCount } =
    countExperiencesHook();
  const [graph, setGraph] = React.useState<GraphData | null>(null);

  const {
    data: experiencesGraphData,
    call: fetchExperiencesGraphData,
    calling: fetchingExperiencesGraphData,
  } = graphExperiencesHook(false);

  const onCardChange = async (title: string, days: number) => {
    const filters = {
      filters: {
        ignoreCGHost: true,
        lastDays: days,
      },
    };

    if (title === 'Total Experiences') {
      filters.filters.ignoreCGHost = true;
      await fetchExperiencesGraphData(filters);
    }
    if (title === 'Total Non-CG Experiences') {
      filters.filters.ignoreCGHost = false;
      await fetchExperiencesGraphData(filters);
    }
  };

  useEffect(() => {
    if (experiencesGraphData) {
      const xData = experiencesGraphData.x.map((x) =>
        DateUtils.dater(x).format('YYYY-MM-DD'),
      );

      setGraph({ x: xData, y: experiencesGraphData.y });
    }
  }, [experiencesGraphData]);

  useEffect(() => {
    onCardChange('Total Experiences', 7);
  }, []);

  const getLineChart = () => {
    const series: Series[] = [];
    if (graph) {
      series.push({ data: graph, type: 'area' });
    }

    if (series.length > 0) {
      return <LineChart series={series} color="#D8607A" precision={0} />;
    }

    return null;
  };

  return (
    <Page>
      <Section>
        <Article>
          <h2>Experiences</h2>
          <Loader loading={fetchingExperiencesCount} horizontal>
            <ChartBoard onChange={onCardChange}>
              <ChartCards key="cards">
                <ChartCard
                  key="total-experiences"
                  title="Total Experiences"
                  value={experiencesCount?.all}
                />
                <ChartCard
                  key="non-cg-experiences"
                  title="Total Non-CG Experiences"
                  value={experiencesCount?.nonCG}
                />
              </ChartCards>

              <ChartGraph
                timelineDays={[7, 30, 90, 120, 180, 270, 365]}
                key="graph"
              >
                <Loader loading={fetchingExperiencesGraphData} horizontal>
                  {getLineChart()}
                </Loader>
              </ChartGraph>
            </ChartBoard>
          </Loader>
        </Article>
      </Section>
    </Page>
  );
}
