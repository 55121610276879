import { createBrowserRouter } from 'react-router-dom';

import React from 'react';
import {
  ExperienceId,
  HostId,
  TicketId,
  UserId,
  VenueId,
} from '@cg/common/src/ids';
import { CopyIconClipboard } from '@cg/module-frontend/src/components';
import { NotFound } from '@cg/module-frontend/src/errors';
import { AppLayout } from '@cg/module-frontend/src';
import { DashboardLayout } from '../layout';
import DashboardPage from '~/pages/dashboard';
import UsersDashboard from '~/pages/users';
import UserPage from '~/pages/users/user';
import FeaturesPage from '../../pages/features';
import { fetchUser } from '~/generated/clients/background/users/BackgroundUsers.client';
import { User } from '~/generated/models/User';
import UserFlagsPage from '~/pages/users/user/flags';
import AllUsersPage from '~/pages/users/all/AllUsers';
import AbandonedUsersPage from '~/pages/users/abandoned/AbandonedUsers';
import TicketsPage from '~/pages/tickets';
import TicketPage from '~/pages/tickets/ticket';
import HostsPage from '~/pages/hosts';
import { Host } from '~/generated/models/Host';
import { fetchHost } from '~/generated/clients/background/hosts/BackgroundHosts.client';
import HostPage from '~/pages/hosts/host';
import ExperiencesDashboard from '~/pages/experiences';
import ExperiencesPage from '~/pages/experiences/all';
import ExperiencePage from '~/pages/experiences/experience';
import { fetchExperience } from '~/generated/clients/background/experiences/BackgroundExperiences.client.ts';
import { Experience } from '~/generated/models/Experience';
import VenuesPage from '~/pages/venues';
import VenuePage from '~/pages/venues/venue';
import { fetchVenue } from '~/generated/clients/background/venues/BackgroundVenues.client.ts';
import { Venue } from '~/generated/models/Venue.ts';
import { fetchTicket } from '~/generated/clients/background/tickets/BackgroundTickets.client';
import { Ticket } from '~/generated/models/Ticket.ts';
import Analytics from '~/pages/analytics';
import EmailCampaigns from '~/pages/campaigns';

export default createBrowserRouter([
  {
    path: '/',
    id: 'root',
    element: <AppLayout />,
    handle: {
      requiresAuth: true,
      crumb: () => ({
        title: 'Home',
        key: 'home',
      }),
    },
    children: [
      {
        path: '/',
        element: <DashboardLayout />,
        children: [
          {
            path: '/',
            element: <DashboardPage />,
          },
          {
            path: '/features',
            id: 'features',
            handle: {
              crumb: () => ({
                title: 'Features',
                key: 'features',
              }),
            },
            element: <FeaturesPage />,
          },
          {
            path: '/analytics',
            id: 'analytics',
            handle: {
              crumb: () => ({
                title: 'Analytics',
                key: 'analytics',
              }),
            },
            element: <Analytics />,
          },
          {
            path: '/campaigns',
            id: 'campaigns',
            handle: {
              crumb: () => ({
                title: 'Campaigns',
                key: 'campaigns',
              }),
            },
            element: <EmailCampaigns />,
          },
          {
            path: '/users',
            id: 'users-dashboard',
            handle: {
              crumb: () => ({
                title: 'Users',
                key: 'users',
              }),
            },
            children: [
              {
                index: true,
                element: <UsersDashboard />,
              },
              {
                path: '/users/all',
                id: 'users',
                handle: {
                  crumb: () => {
                    return {
                      title: 'All',
                      key: 'all-users',
                    };
                  },
                },
                element: <AllUsersPage />,
              },
              {
                path: '/users/abandoned',
                id: 'users-abandoned',
                handle: {
                  crumb: () => {
                    return {
                      title: 'Abandoned',
                      key: 'abandoned-users',
                    };
                  },
                },
                element: <AbandonedUsersPage />,
              },
              {
                path: '/users/:userId',
                id: 'user',
                loader: async ({ params }) => {
                  const { userId } = params;
                  const user = await fetchUser({
                    ids: {
                      userId: new UserId(userId),
                    },
                  });
                  return user.payload;
                },
                handle: {
                  crumb: (user: User) => {
                    return {
                      title: (
                        <CopyIconClipboard text={user.id.getValue()}>
                          {user.email} ({user.id.getValue()})
                        </CopyIconClipboard>
                      ),
                    };
                  },
                },
                children: [
                  {
                    index: true,
                    element: <UserPage />,
                  },
                  {
                    path: '/users/:userId/flags',
                    id: 'user-flags',
                    handle: {
                      crumb: () => ({
                        title: 'Flags',
                        key: 'flags',
                      }),
                    },
                    element: <UserFlagsPage />,
                  },
                ],
              },
            ],
          },
          {
            path: '/tickets',
            id: 'tickets',
            handle: {
              crumb: () => ({
                title: 'Tickets',
                key: 'tickets',
              }),
            },
            children: [
              {
                index: true,
                element: <TicketsPage />,
              },
              {
                path: '/tickets/:ticketId',
                id: 'ticket',
                loader: async ({ params }) => {
                  const { ticketId } = params;
                  const ticket = await fetchTicket({
                    ids: {
                      ticketId: new TicketId(ticketId),
                    },
                  });
                  if (!ticket.succeeded) {
                    throw new NotFound('Ticket not found');
                  }

                  const user = await fetchUser({
                    ids: {
                      userId: ticket.payload.userId,
                    },
                  });
                  return { ticket: ticket.payload, user: user.payload };
                },
                handle: {
                  crumb: ({ ticket, user }: { user: User; ticket: Ticket }) => {
                    return {
                      title: (
                        <CopyIconClipboard text={ticket.id.getValue()}>
                          {user.firstName} {user.lastName} (
                          {ticket.id.getValue()})
                        </CopyIconClipboard>
                      ),
                      key: ticket.id.getValue(),
                    };
                  },
                },
                element: <TicketPage />,
              },
            ],
          },
          {
            path: '/experiences',
            id: 'experiences-dashboard',
            handle: {
              crumb: () => ({
                title: 'Experiences',
                key: 'experiences',
              }),
            },
            children: [
              {
                index: true,
                element: <ExperiencesDashboard />,
                handle: {
                  crumb: () => ({
                    title: 'Dashboard',
                    key: 'experiences-dashboard',
                  }),
                },
              },
              {
                path: '/experiences/all',
                id: 'all',
                element: <ExperiencesPage />,
                handle: {
                  crumb: () => ({
                    title: 'View All',
                    key: 'experiences',
                  }),
                },
              },
              {
                path: '/experiences/:experienceId',
                id: 'experience',
                loader: async ({ params }) => {
                  const { experienceId } = params;
                  const experience = await fetchExperience({
                    ids: {
                      experienceId: new ExperienceId(experienceId),
                    },
                  });
                  return experience.payload;
                },
                handle: {
                  crumb: (experience: Experience) => {
                    return {
                      title: (
                        <CopyIconClipboard text={experience.id.getValue()}>
                          {experience.title} ({experience.id.getValue()})
                        </CopyIconClipboard>
                      ),
                      key: experience.id.getValue(),
                    };
                  },
                },
                element: <ExperiencePage />,
              },
            ],
          },
          {
            path: '/venues',
            id: 'venues',
            handle: {
              crumb: () => ({
                title: 'Venues',
                key: 'venues',
              }),
            },
            children: [
              {
                index: true,
                element: <VenuesPage />,
              },
              {
                path: '/venues/:venueId',
                id: 'venue',
                loader: async ({ params }) => {
                  const { venueId } = params;
                  const venue = await fetchVenue({
                    ids: {
                      venueId: new VenueId(venueId),
                    },
                  });
                  return venue.payload;
                },
                handle: {
                  crumb: (venue: Venue) => {
                    return {
                      title: venue.name,
                      key: venue.id.getValue(),
                    };
                  },
                },
                element: <VenuePage />,
              },
            ],
          },
          {
            path: '/hosts',
            id: 'hosts',
            handle: {
              crumb: () => ({
                title: 'Hosts',
                key: 'hosts',
              }),
            },
            children: [
              {
                index: true,
                element: <HostsPage />,
              },
              {
                path: '/hosts/:hostId',
                id: 'host',
                loader: async ({ params }) => {
                  const { hostId } = params;
                  const host = await fetchHost({
                    ids: {
                      hostId: new HostId(hostId),
                    },
                  });
                  return host.payload;
                },
                handle: {
                  crumb: (host: Host) => {
                    return {
                      title: (
                        <CopyIconClipboard text={host.id.getValue()}>
                          {host.uniqueName} ({host.id.getValue()})
                        </CopyIconClipboard>
                      ),
                      key: host.id.getValue(),
                    };
                  },
                },
                element: <HostPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
