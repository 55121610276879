/* eslint-disable */


import { useHttp } from '@cg/frontend-core';

import { createExperience, CreateExperienceArgs, createQuestion, CreateQuestionArgs, deleteExperience, DeleteExperienceArgs, deleteQuestion, DeleteQuestionArgs, duplicateExperience, DuplicateExperienceArgs, fetchAddress, FetchAddressArgs, fetchExperience, FetchExperienceArgs, fetchFailureConfirmation, FetchFailureConfirmationArgs, fetchSuccessConfirmation, FetchSuccessConfirmationArgs, fetchWaitlistConfirmation, FetchWaitlistConfirmationArgs, listAnswers, ListAnswersArgs, listConfirmations, ListConfirmationsArgs, listExperiences, ListExperiencesArgs, listExperiencesFiltered, ListExperiencesFilteredArgs, listQuestions, ListQuestionsArgs, publishExperience, PublishExperienceArgs, updateAddress, UpdateAddressArgs, updateConfirmation, UpdateConfirmationArgs, updateExperience, UpdateExperienceArgs, updateQuestion, UpdateQuestionArgs, uploadPhoto, UploadPhotoArgs, waitlistedUsers, WaitlistedUsersArgs } from './PlaygroundHostsExperiences.client';

export const createExperienceHook = (immediateCall: boolean = true, initArgs?: CreateExperienceArgs) => {
  return useHttp(createExperience, { immediateCall, initArgs })
};
export const createQuestionHook = (immediateCall: boolean = true, initArgs?: CreateQuestionArgs) => {
  return useHttp(createQuestion, { immediateCall, initArgs })
};
export const deleteExperienceHook = (immediateCall: boolean = true, initArgs?: DeleteExperienceArgs) => {
  return useHttp(deleteExperience, { immediateCall, initArgs })
};
export const deleteQuestionHook = (immediateCall: boolean = true, initArgs?: DeleteQuestionArgs) => {
  return useHttp(deleteQuestion, { immediateCall, initArgs })
};
export const duplicateExperienceHook = (immediateCall: boolean = true, initArgs?: DuplicateExperienceArgs) => {
  return useHttp(duplicateExperience, { immediateCall, initArgs })
};
export const fetchAddressHook = (immediateCall: boolean = true, initArgs?: FetchAddressArgs) => {
  return useHttp(fetchAddress, { immediateCall, initArgs })
};
export const fetchExperienceHook = (immediateCall: boolean = true, initArgs?: FetchExperienceArgs) => {
  return useHttp(fetchExperience, { immediateCall, initArgs })
};
export const fetchFailureConfirmationHook = (immediateCall: boolean = true, initArgs?: FetchFailureConfirmationArgs) => {
  return useHttp(fetchFailureConfirmation, { immediateCall, initArgs })
};
export const fetchSuccessConfirmationHook = (immediateCall: boolean = true, initArgs?: FetchSuccessConfirmationArgs) => {
  return useHttp(fetchSuccessConfirmation, { immediateCall, initArgs })
};
export const fetchWaitlistConfirmationHook = (immediateCall: boolean = true, initArgs?: FetchWaitlistConfirmationArgs) => {
  return useHttp(fetchWaitlistConfirmation, { immediateCall, initArgs })
};
export const listAnswersHook = (immediateCall: boolean = true, initArgs?: ListAnswersArgs) => {
  return useHttp(listAnswers, { immediateCall, initArgs })
};
export const listConfirmationsHook = (immediateCall: boolean = true, initArgs?: ListConfirmationsArgs) => {
  return useHttp(listConfirmations, { immediateCall, initArgs })
};
export const listExperiencesHook = (immediateCall: boolean = true, initArgs?: ListExperiencesArgs) => {
  return useHttp(listExperiences, { immediateCall, initArgs })
};
export const listExperiencesFilteredHook = (immediateCall: boolean = true, initArgs?: ListExperiencesFilteredArgs) => {
  return useHttp(listExperiencesFiltered, { immediateCall, initArgs })
};
export const listQuestionsHook = (immediateCall: boolean = true, initArgs?: ListQuestionsArgs) => {
  return useHttp(listQuestions, { immediateCall, initArgs })
};
export const publishExperienceHook = (immediateCall: boolean = true, initArgs?: PublishExperienceArgs) => {
  return useHttp(publishExperience, { immediateCall, initArgs })
};
export const updateAddressHook = (immediateCall: boolean = true, initArgs?: UpdateAddressArgs) => {
  return useHttp(updateAddress, { immediateCall, initArgs })
};
export const updateConfirmationHook = (immediateCall: boolean = true, initArgs?: UpdateConfirmationArgs) => {
  return useHttp(updateConfirmation, { immediateCall, initArgs })
};
export const updateExperienceHook = (immediateCall: boolean = true, initArgs?: UpdateExperienceArgs) => {
  return useHttp(updateExperience, { immediateCall, initArgs })
};
export const updateQuestionHook = (immediateCall: boolean = true, initArgs?: UpdateQuestionArgs) => {
  return useHttp(updateQuestion, { immediateCall, initArgs })
};
export const uploadPhotoHook = (immediateCall: boolean = true, initArgs?: UploadPhotoArgs) => {
  return useHttp(uploadPhoto, { immediateCall, initArgs })
};
export const waitlistedUsersHook = (immediateCall: boolean = true, initArgs?: WaitlistedUsersArgs) => {
  return useHttp(waitlistedUsers, { immediateCall, initArgs })
};
