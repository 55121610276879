import {
  Article,
  Button,
  Loader,
  Page,
  Section,
  Table,
  TableRowItem,
  TableSortDirection,
  UserAvatar,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { UserGroupIcon, UserIcon } from '@cg/module-frontend/src/icons';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import { User } from '~/generated/models/User';
import CreateHost from '~/pages/hosts/components/CreateHost';
import { BasicHost } from '~/generated/models/BasicHost.ts';

type HostsPageProps = {
  hosts: BasicHost[];
  waitlisted: User[];
  nextPage: () => Promise<void>;
  hasNextPage: boolean;
  callingMore: boolean;
};

type Filters = {
  createDateSort: TableSortDirection | null;
};

const filterHosts = (hosts: BasicHost[], filters: Filters): BasicHost[] => {
  let filtered = [...hosts];

  if (filters.createDateSort) {
    filtered = filtered.sort((a, b) => {
      const aTime = DateUtils.diffFromNow(a.dateCreated);
      const bTime = DateUtils.diffFromNow(b.dateCreated);

      return filters.createDateSort === 'asc' ? aTime - bTime : bTime - aTime;
    });
  }

  return filtered;
};

export default function HostsPage({
  hosts,
  waitlisted,
  hasNextPage,
  nextPage,
  callingMore,
}: HostsPageProps) {
  const navigate = useNavigate();
  const [createDateSort, setCreateDateSort] =
    useState<TableSortDirection | null>(null);
  const [userHost, setUserHost] = React.useState<User | null>(null);
  const [createHost, showCreateHost] = React.useState(false);

  useEffect(() => {
    if (userHost && !createHost) {
      showCreateHost(true);
    }
  }, [userHost]);

  const listHeaders = [
    'Friendly Name',
    'Unique Name',
    {
      label: 'Created',
      key: 'created',
      filter: setCreateDateSort,
    },
    'Type',
  ];
  const listRows: TableRowItem[] = filterHosts(hosts, { createDateSort }).map(
    (host) => {
      return {
        onClick: () => navigate(`/hosts/${host.id.getValue()}`),
        className: 'bg-white border-grey-darker cursor-pointer',
        key: host.id.getValue(),
        cells: [
          <div className="flex flex-row items-center">
            <UserAvatar host={host} iconSize="md" expandable />
            <span className="ml-2 whitespace-nowrap">{host.friendlyName}</span>
          </div>,
          host.uniqueName,
          DateUtils.formatted('MMM Do, YYYY', host.dateCreated, 'N/A'),
          {
            label: host.isPersonal ? (
              <UserIcon className="size-6" />
            ) : (
              <UserGroupIcon className="size-6" />
            ),
          },
        ],
      };
    },
  );

  const waitlistHeaders = ['Name', 'Email', 'Phone', ''];
  const waitlistRows: TableRowItem[] = waitlisted.map((user) => {
    return {
      onClick: () => navigate(`/users/${user.id.getValue()}`),
      className: 'bg-white border-grey-darker cursor-pointer',
      key: user.id.getValue(),
      cells: [
        <div className="flex flex-row items-center">
          <UserAvatar user={user} iconSize="md" expandable />
          <span className="ml-2 whitespace-nowrap">
            {user.firstName} {user.lastName}
          </span>
        </div>,
        user.email,
        user.phoneNumber,
        {
          label: (
            <Button
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setUserHost(user);
              }}
            >
              Create Host
            </Button>
          ),
        },
      ],
    };
  });

  return (
    <Page>
      <CreateHost
        show={createHost}
        onClose={() => showCreateHost(false)}
        user={userHost}
      />
      <Section>
        <Article>
          <div className="flex gap-5 flex-col w-full">
            <div className="flex justify-between items-center">
              <h2 className="justify-center text-black">All Hosts</h2>
              <Button color="primary" onClick={() => showCreateHost(true)}>
                Create Host
              </Button>
            </div>

            <Table headers={listHeaders} rows={listRows} />
            <Loader horizontal loading={callingMore} />
            {hasNextPage && (
              <Button
                disabled={callingMore}
                color="secondary"
                className="w-full flex justify-center"
                onClick={nextPage}
              >
                Fetch More
              </Button>
            )}
          </div>
        </Article>

        <Article>
          <div className="flex gap-5 flex-col w-full">
            <h2 className="justify-center text-black">Waitlisted Users</h2>

            <Table headers={waitlistHeaders} rows={waitlistRows} />
          </div>
        </Article>
      </Section>
    </Page>
  );
}
