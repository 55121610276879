/* eslint-disable */


import { http, HttpResult } from '@cg/frontend-core';

import { CreateExperienceBySeriesRequest } from '../../../../models/CreateExperienceBySeriesRequest';
import { Experience } from '../../../../models/Experience';
import { HostId } from '@cg/common/src/ids/HostId';
import { ExperienceSeriesId } from '@cg/common/src/ids/ExperienceSeriesId';
import { CreateExperienceSeriesRequest } from '../../../../models/CreateExperienceSeriesRequest';
import { ExperienceSeries } from '../../../../models/ExperienceSeries';
import { Address } from '../../../../models/Address';
import { Page } from '@cg/common/src/paging/Page';
import { HostGuest } from '../../../../models/HostGuest';
import { ExperienceSeriesMetrics } from '../../../../models/ExperienceSeriesMetrics';
import { ExperienceSeriesSchedule } from '../../../../models/ExperienceSeriesSchedule';
import { UpdateAddressRequest } from '../../../../models/UpdateAddressRequest';
import { UpdateExperienceSeriesScheduleRequest } from '../../../../models/UpdateExperienceSeriesScheduleRequest';
import { UpdateExperienceSeriesRequest } from '../../../../models/UpdateExperienceSeriesRequest';
import { CreatePhotoPreSignedUrlRequest } from '../../../../models/CreatePhotoPreSignedUrlRequest';
import { PreSignedUrlResponse } from '../../../../models/PreSignedUrlResponse';

export type CreateExperienceArgs = {
    verifiedUser?: boolean;
    ids: {
        hostId: HostId;
        experienceSeriesId: ExperienceSeriesId;
    };
    filters?: {
        previousOnly?: boolean;
        upcomingOnly?: boolean;
    };
      body: CreateExperienceBySeriesRequest;
}

/**
 * @summary Creates a new Experience for the Experience Series
 * @param {CreateExperienceArgs} args
 */
export const createExperience = (args: CreateExperienceArgs): Promise<HttpResult<Experience>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/series/{experienceSeriesId}/experiences', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type CreateSeriesArgs = {
    verifiedUser?: boolean;
    ids: {
        hostId: HostId;
    };
      body: CreateExperienceSeriesRequest;
}

/**
 * @summary Endpoint for creating a new Experience Series for this Host.
 * @param {CreateSeriesArgs} args
 */
export const createSeries = (args: CreateSeriesArgs): Promise<HttpResult<ExperienceSeries>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/series', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type DeleteScheduleArgs = {
    verifiedUser?: boolean;
    ids: {
        hostId: HostId;
        experienceSeriesId: ExperienceSeriesId;
    };
}

/**
 * @summary Endpoint for deleting the Schedule
 * @param {DeleteScheduleArgs} args
 */
export const deleteSchedule = (args: DeleteScheduleArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/series/{experienceSeriesId}/schedules', params);

    return http.delete(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchAddressArgs = {
    verifiedUser?: boolean;
    ids: {
        hostId: HostId;
        experienceSeriesId: ExperienceSeriesId;
    };
}

/**
 * @summary Endpoint for getting the Experience Series address
 * @param {FetchAddressArgs} args
 */
export const fetchAddress = (args: FetchAddressArgs): Promise<HttpResult<Address>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/series/{experienceSeriesId}/address', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchExperiencesArgs = {
    verifiedUser?: boolean;
    ids: {
        hostId: HostId;
        experienceSeriesId: ExperienceSeriesId;
    };
    filters?: {
        previousOnly?: boolean;
        upcomingOnly?: boolean;
        pageSize?: number;
        pageToken?: string;
    };
}

/**
 * @summary Endpoint for listing experiences belonging to a series
 * @param {FetchExperiencesArgs} args
 */
export const fetchExperiences = (args: FetchExperiencesArgs): Promise<HttpResult<Page<Experience>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/series/{experienceSeriesId}/experiences', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchSeriesArgs = {
    verifiedUser?: boolean;
    ids: {
        hostId: HostId;
        experienceSeriesId: ExperienceSeriesId;
    };
}

/**
 * <p>Returns the Experience Series</p>
 * @summary Endpoint for returning an Experience Series.
 * @param {FetchSeriesArgs} args
 */
export const fetchSeries = (args: FetchSeriesArgs): Promise<HttpResult<ExperienceSeries>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/series/{experienceSeriesId}', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchSeriesGuestsArgs = {
    verifiedUser?: boolean;
    ids: {
        hostId: HostId;
        experienceSeriesId: ExperienceSeriesId;
    };
    filters?: {
        fake?: string;
        pageSize?: number;
        pageToken?: string;
    };
}

/**
 * <p>Returns the list of Experience series that are owned by this Host.</p>
 * @summary Endpoint for returning list of Experience Series that belong to this Host.
 * @param {FetchSeriesGuestsArgs} args
 */
export const fetchSeriesGuests = (args: FetchSeriesGuestsArgs): Promise<HttpResult<Page<HostGuest>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/series/{experienceSeriesId}/guests', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchSeriesMetricsArgs = {
    verifiedUser?: boolean;
    ids: {
        hostId: HostId;
        experienceSeriesId: ExperienceSeriesId;
    };
}

/**
 * <p>Returns the list of Experience series that are owned by this Host.</p>
 * @summary Endpoint for returning list of Experience Series that belong to this Host.
 * @param {FetchSeriesMetricsArgs} args
 */
export const fetchSeriesMetrics = (args: FetchSeriesMetricsArgs): Promise<HttpResult<ExperienceSeriesMetrics>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/series/{experienceSeriesId}/metrics', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListSchedulesArgs = {
    verifiedUser?: boolean;
    ids: {
        hostId: HostId;
        experienceSeriesId: ExperienceSeriesId;
    };
}

/**
 * @summary Endpoint for getting the Experience Series Schedule
 * @param {ListSchedulesArgs} args
 */
export const listSchedules = (args: ListSchedulesArgs): Promise<HttpResult<ExperienceSeriesSchedule[]>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/series/{experienceSeriesId}/schedules', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListSeriesArgs = {
    verifiedUser?: boolean;
    ids: {
        hostId: HostId;
    };
    filters?: {
        pageSize?: number;
        pageToken?: string;
    };
}

/**
 * <p>Returns the list of Experience series that are owned by this Host.</p>
 * @summary Endpoint for returning list of Experience series that belong to this Host.
 * @param {ListSeriesArgs} args
 */
export const listSeries = (args: ListSeriesArgs): Promise<HttpResult<Page<ExperienceSeries>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/series', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateAddressArgs = {
    verifiedUser?: boolean;
    ids: {
        hostId: HostId;
        experienceSeriesId: ExperienceSeriesId;
    };
      body: UpdateAddressRequest;
}

/**
 * @summary Endpoint for updating the Address of a Experience Series.
 * @param {UpdateAddressArgs} args
 */
export const updateAddress = (args: UpdateAddressArgs): Promise<HttpResult<Address>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/series/{experienceSeriesId}/address', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateScheduleArgs = {
    verifiedUser?: boolean;
    ids: {
        hostId: HostId;
        experienceSeriesId: ExperienceSeriesId;
    };
      body: UpdateExperienceSeriesScheduleRequest;
}

/**
 * @summary Endpoint for creating/updating the Schedule
 * @param {UpdateScheduleArgs} args
 */
export const updateSchedule = (args: UpdateScheduleArgs): Promise<HttpResult<ExperienceSeriesSchedule[]>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/series/{experienceSeriesId}/schedules', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateSeriesArgs = {
    verifiedUser?: boolean;
    ids: {
        hostId: HostId;
        experienceSeriesId: ExperienceSeriesId;
    };
      body: UpdateExperienceSeriesRequest;
}

/**
 * @summary Endpoint for updating the Experience Series
 * @param {UpdateSeriesArgs} args
 */
export const updateSeries = (args: UpdateSeriesArgs): Promise<HttpResult<ExperienceSeries>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/series/{experienceSeriesId}', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UploadPhotoArgs = {
    verifiedUser?: boolean;
    ids: {
        hostId: HostId;
        experienceSeriesId: ExperienceSeriesId;
    };
      body: CreatePhotoPreSignedUrlRequest;
}

/**
 * <p>API endpoints for creating a Pre Signed URL for uploading files after to S3</p>
 * @summary Generates a Pre Signed URL for uploading a photo for an Experience
 * @param {UploadPhotoArgs} args
 */
export const uploadPhoto = (args: UploadPhotoArgs): Promise<HttpResult<PreSignedUrlResponse>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/series/{experienceSeriesId}/photo', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
