import { GoogleMap, useJsApiLoader, Libraries } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Id } from '@cg/common/src/ids';
import Circle from './Circle';
import Marker from './Marker';
import { AddressOrCoordinate } from '~/generated/models/AddressOrCoordinate';
import { Loader } from '../loader';
import { DetailedExperience } from '~/generated/models/DetailedExperience';
import { PinColors } from './colors.ts';
import { DetailedExperienceSeries } from '~/generated/models/DetailedExperienceSeries.ts';

export type MarkerPointer = {
  id?: Id;
  address?: AddressOrCoordinate;
  secret?: boolean;
  entity?: DetailedExperience | DetailedExperienceSeries;
  color?: PinColors;
};

type Props = {
  markers: MarkerPointer[];
  className?: string;
  onClick?: (marker: MarkerPointer) => void;
};

const libraries = ['places'] as Libraries;
const zoom = 13;

function Map({ markers, className, onClick }: Props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [focus, setFocus] = useState<MarkerPointer>(markers[0]);

  useEffect(() => {
    if (!focus) {
      setFocus(markers[0]);
    }
  }, [markers]);
  const center = focus
    ? { lat: focus.address?.latitude ?? 0, lng: focus.address?.longitude ?? 0 }
    : undefined;

  return (
    <Loader loading={!isLoaded}>
      <GoogleMap
        mapContainerClassName={classNames(className, 'h-60 w-full rounded')}
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          keyboardShortcuts: false,
          fullscreenControl: false,
        }}
        center={center}
        zoom={zoom}
      >
        {markers.map((marker, index) => {
          const { color, address, secret, entity } = marker;

          if (secret) {
            return (
              <Circle
                key={`${address?.latitude}-${address?.longitude}-${entity?.id}`}
                center={{
                  lat: address?.latitude ?? 0,
                  lng: address?.longitude ?? 0,
                }}
                color={color}
              />
            );
          }

          return (
            <Marker
              key={`${entity?.id}-${address?.latitude}-${address?.longitude}`}
              onClick={() => {
                if (onClick) {
                  onClick(marker);
                  setFocus(markers[index]);
                }
              }}
              color={color}
              address={address}
              secret={secret}
            />
          );
        })}
      </GoogleMap>
    </Loader>
  );
}

export default Map;
