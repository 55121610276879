import { Link, LinkProps } from 'react-router-dom';
import * as React from 'react';
import classNames from 'classnames';
import { usePostHog } from 'posthog-js/react';
import { Properties } from 'posthog-js';
import { useScreenSize } from '../../hooks';
import { handleOnClick } from './utils';

type Props = LinkProps &
  React.RefAttributes<HTMLAnchorElement> & {
    reload?: boolean;
    track?: Properties;
    asIcon?: boolean;
    asButton?: boolean;
  };

export default function Href({
  children,
  className,
  onClick,
  track,
  reload,
  to,
  asIcon,
  asButton,
  ...rest
}: Props) {
  const posthog = usePostHog();
  const { isMobile } = useScreenSize();
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      // eslint-disable-next-line
      if (reload && to && typeof to === 'string') {
        event.preventDefault();
        event.stopPropagation();
        // eslint-disable-next-line
        window.location.href = to;
      }

      if (onClick) {
        onClick(event);
      }

      if (posthog && track) {
        if (posthog && track) {
          handleOnClick(posthog, track, isMobile);
        }
      }
    },
    [posthog, track, reload, to, isMobile, onClick],
  );

  return (
    <Link
      className={classNames(
        {
          'underline text-primary hover:text-primary-darker visited:text-primary':
            !asIcon && !asButton,
        },
        className,
      )}
      onClick={handleClick}
      to={to}
      {...rest}
    >
      {children}
    </Link>
  );
}
