/* eslint-disable */


import { http, HttpResult } from '@cg/frontend-core';

import { UserCount } from '../../../../models/UserCount';
import { GraphData } from '../../../../models/GraphData';
import { UserStatus } from '../../../../models/UserStatus';

export type CountUsersArgs = {
    verifiedUser?: boolean;
}

/**
 * @summary Returns a list of Users
 */
export const countUsers = (args: CountUsersArgs): Promise<HttpResult<UserCount>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/aggregates/users/count', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type GraphUserCountArgs = {
    verifiedUser?: boolean;
    filters?: {
        status?: UserStatus;
        logged30Days?: boolean;
        lastDays?: number;
    };
}

/**
 * @param {GraphUserCountArgs} args
 */
export const graphUserCount = (args: GraphUserCountArgs): Promise<HttpResult<GraphData>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/aggregates/users/graph', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
