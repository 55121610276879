/* eslint-disable */


import { useHttp } from '@cg/frontend-core';

import { countExperiences, graphExperiences, GraphExperiencesArgs } from './BackgroundAggregatesExperiences.client';

export const countExperiencesHook = (immediateCall: boolean = true, ) => {
  return useHttp(countExperiences, { immediateCall })
};
export const graphExperiencesHook = (immediateCall: boolean = true, initArgs?: GraphExperiencesArgs) => {
  return useHttp(graphExperiences, { immediateCall, initArgs })
};
