import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '@cg/frontend-core';
import {
  FacebookIcon,
  InstagramIcon,
  TikTokIcon,
  WhatsAppIcon,
} from '../../icons';
import { boxConfig } from '../../config';
import { Href, SocialLink } from '../links';

type FooterLinks = {
  title: string;
  items: {
    label: string;
    to?: string;
    target?: string;
    onClick?: () => void;
  }[];
};
const footerLinks = (isLogged: boolean, login: () => void): FooterLinks[] => [
  {
    title: 'Common Ground',
    items: [
      { label: 'Vibe Check', to: '/vibe-check' },
      {
        label: 'Become a Host',
        to: `${boxConfig.baseUrls.commonGround}/become-a-host`,
      },
      {
        label: 'WhatsApp Community',
        to: 'https://bit.ly/45dKir8',
        target: '_blank',
      },
    ],
  },
  {
    title: 'You',
    items: isLogged
      ? [
          {
            label: 'My Experiences',
            to: `${boxConfig.baseUrls.commonGround}/profile/experiences`,
          },
          {
            label: 'My Profile',
            to: `${boxConfig.baseUrls.commonGround}/profile/details`,
          },
        ]
      : [
          {
            label: 'Sign Up',
            onClick: login,
          },
          {
            label: 'Login',
            onClick: login,
          },
        ],
  },
  {
    title: 'Us',
    items: [
      {
        label: 'About',
        to: `${boxConfig.baseUrls.commonGround}/about-us`,
      },
      {
        label: 'Get in Touch',
        to: `${boxConfig.baseUrls.commonGround}/contact-us`,
      },
    ],
  },
];

const legalLinks = [
  {
    to: `${boxConfig.baseUrls.commonGround}/tos`,
    label: 'Terms of Service',
  },
  {
    to: `${boxConfig.baseUrls.commonGround}/privacy-policy`,
    label: 'Privacy Policy',
  },
  {
    to: `${boxConfig.baseUrls.commonGround}/cookie-policy`,
    label: 'Cookie Policy',
  },
  {
    to: `${boxConfig.baseUrls.commonGround}/contact-us`,
    label: 'Help',
  },
];
const legalDoms: React.ReactNode[] = legalLinks.map(({ to, label }) => {
  return (
    <Link key={to} to={to}>
      {label}
    </Link>
  );
});

const socialLinks = [
  {
    to: 'https://www.facebook.com/joincommonground',
    icon: <FacebookIcon className="size-8" />,
  },
  {
    to: 'https://www.tiktok.com/@joincommonground',
    icon: <TikTokIcon className="size-8" />,
  },
  {
    to: 'https://bit.ly/45dKir8',
    icon: <WhatsAppIcon className="size-8" />,
  },
  {
    to: 'https://www.instagram.com/joincommonground',
    icon: <InstagramIcon className="size-8" />,
  },
];

type Props = {
  dark?: boolean;
};

function Footer({ dark }: Props) {
  const { isLogged, login } = useAuth();
  const textColor = dark ? 'text-black' : 'text-white';
  const currentYear = new Date().getFullYear();

  return (
    <footer className={`w-full bg-footer ${textColor} text-sm`}>
      <div className="w-mobile lg:w-desktop mx-auto text-center lg:text-left pt-8 pb-16 lg:py-8">
        <div className="flex flex-col lg:flex-row items-start justify-between">
          {footerLinks(isLogged, login).map(({ title, items }) => (
            <React.Fragment key={title}>
              <div className="w-full lg:w-auto mb-6 lg:mb-0 flex justify-center">
                <div>
                  <span className="font-semibold leading-snug">
                    {title}
                    <br />
                  </span>
                  <ul className="flex flex-col items-center lg:items-start space-y-2 mt-2">
                    {items.map(({ label, to, target, onClick }) => (
                      <li key={label}>
                        <Href
                          asButton
                          to={to ?? '#'}
                          onClick={(e) => {
                            if (onClick) {
                              e.preventDefault();
                              e.stopPropagation();
                              onClick();
                            }
                          }}
                          target={target}
                          className="font-light leading-tight"
                        >
                          {label}
                        </Href>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </React.Fragment>
          ))}
          <div className="w-full lg:w-fit flex justify-center lg:justify-start">
            <div className="flex-1">
              <span className="font-semibold leading-snug lg:ml-3 lg:text-center">
                Let&apos;s be Friends
              </span>
              <div className="grid grid-cols-2 gap-y-4 mt-3 place-items-center">
                {socialLinks.map(({ to, icon }) => (
                  <SocialLink
                    key={to}
                    to={to}
                    icon={icon}
                    className={textColor}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-secondary my-5" />
        <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-between text-center lg:text-left">
          <span className="text-zinc-500 font-medium leading-tight tracking-tight">
            © {currentYear} Common Ground Social Network Inc.
          </span>
          <div className="flex flex-col lg:flex-row lg:items-center">
            <span className="text-zinc-500 font-medium leading-tight">
              {legalDoms.reduce((prev, curr) => [prev, ' | ', curr])}
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
