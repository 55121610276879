import { ComponentType, FC } from 'react';
import { WithAuthenticationRequiredOptions } from '@auth0/auth0-react/src/with-authentication-required';

import { withAuthenticationRequired } from '@auth0/auth0-react';

const withAuthentication = <P extends object>(
  Component: ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {},
): FC<P> => {
  // if (
  //   import.meta.env.VITE_API_AUTHENTICATED_USER &&
  //   UserId.isValid(import.meta.env.VITE_API_AUTHENTICATED_USER) &&
  //   import.meta.env.VITE_API_BYPASS
  // ) {
  //   return function HOC(props) {
  //     return React.createElement(Component, { ...props });
  //   };
  // }

  return withAuthenticationRequired<P>(Component, options);
};

export default withAuthentication;
