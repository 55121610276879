/* eslint-disable */


import { useHttp } from '@cg/frontend-core';

import { createCampaign, CreateCampaignArgs, fetchExperience, FetchExperienceArgs, generateReport, GenerateReportArgs, listExperiences, ListExperiencesArgs, listExperienceUsers, ListExperienceUsersArgs, listTiers, ListTiersArgs, payExperience, PayExperienceArgs } from './BackgroundExperiences.client';

export const createCampaignHook = (immediateCall: boolean = true, initArgs?: CreateCampaignArgs) => {
  return useHttp(createCampaign, { immediateCall, initArgs })
};
export const fetchExperienceHook = (immediateCall: boolean = true, initArgs?: FetchExperienceArgs) => {
  return useHttp(fetchExperience, { immediateCall, initArgs })
};
export const generateReportHook = (immediateCall: boolean = true, initArgs?: GenerateReportArgs) => {
  return useHttp(generateReport, { immediateCall, initArgs })
};
export const listExperiencesHook = (immediateCall: boolean = true, initArgs?: ListExperiencesArgs) => {
  return useHttp(listExperiences, { immediateCall, initArgs })
};
export const listExperienceUsersHook = (immediateCall: boolean = true, initArgs?: ListExperienceUsersArgs) => {
  return useHttp(listExperienceUsers, { immediateCall, initArgs })
};
export const listTiersHook = (immediateCall: boolean = true, initArgs?: ListTiersArgs) => {
  return useHttp(listTiers, { immediateCall, initArgs })
};
export const payExperienceHook = (immediateCall: boolean = true, initArgs?: PayExperienceArgs) => {
  return useHttp(payExperience, { immediateCall, initArgs })
};
