import React from 'react';
import classNames from 'classnames';
import { SectionProps } from './Section';
import { boxConfig } from '../../config';
import GetStarted from './GetStarted';
import { useScreenSize } from '../../hooks/media.hooks';

export type ContentProps = {
  hideGetStarted?: boolean;
  className?: string;
  children?:
    | React.ReactElement<SectionProps>
    | Array<React.ReactElement<SectionProps>>
    | React.ReactNode;
};

function Content({ children, className, hideGetStarted }: ContentProps) {
  const { isSmall } = useScreenSize();
  const showGetStarted =
    window.location.host === boxConfig.hosts.commonGround && !hideGetStarted;

  if (isSmall) {
    return (
      <div className={classNames('w-full', className)}>
        {children}
        <div className="px-4">{showGetStarted && <GetStarted />}</div>
      </div>
    );
  }

  return (
    <div className={classNames('w-mobile lg:w-desktop mx-auto', className)}>
      {children}
      {showGetStarted && <GetStarted />}
    </div>
  );
}

export default Content;
