import { Dropdown, TextInput } from 'flowbite-react';
import React, { useState, ReactNode } from 'react';
import classNames from 'classnames';
import { EllipsisVerticalIcon } from '../../icons';
import { Button } from '../buttons';

type ActionItem = {
  label: string;
  disabled?: boolean;
  loading?: boolean;
  icon: ReactNode;
  onClick: () => void;
};

const isActionItem = (cell: ActionItem | ReactNode): cell is ActionItem => {
  return typeof cell === 'object' && cell !== null && 'label' in cell;
};

type TableSearchBarProps = {
  fullWidth?: boolean;
  onSearch: (searchTerm: string) => void;
  actionItems?: (ReactNode | ActionItem)[];
  moreActionItems?: ActionItem[];
};

export default function TableSearchBar({
  onSearch,
  actionItems,
  moreActionItems,
  fullWidth,
}: TableSearchBarProps) {
  actionItems = actionItems ?? [];
  moreActionItems = moreActionItems ?? [];
  const [searchTerm, setSearchTerm] = useState<string>('');

  return (
    <div
      className={classNames('flex', {
        'flex-col md:flex-row md:justify-end items-center md:items-start':
          !fullWidth,
        'flex-col w-full': fullWidth,
      })}
    >
      <div className="w-full md:w-auto mb-4 md:mb-0 md:flex md:justify-end md:items-center md:mr-5">
        <TextInput
          placeholder="Search by everything"
          className="w-full md:min-w-96"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            onSearch(e.target.value);
          }}
        />
      </div>
      <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-end items-center gap-5 w-full md:w-auto">
        {actionItems.map((item) => {
          if (isActionItem(item)) {
            return (
              <Button
                color="grey"
                disabled={item.disabled ?? false}
                loading={item.loading ?? false}
                onClick={(e) => {
                  e.stopPropagation();
                  item.onClick();
                }}
                key={item.label}
                icon={item.icon}
              >
                <span className="whitespace-nowrap">{item.label}</span>
              </Button>
            );
          }

          if (React.isValidElement(item)) {
            const existingClasses = item.props.className || '';
            return React.cloneElement(item, {
              // @ts-ignore
              className: `${existingClasses} bg-grey-lighter p-2 rounded-xl`,
            });
          }

          return item;
        })}
        {moreActionItems.length > 0 && (
          <Dropdown
            inline
            arrowIcon={false}
            label={
              <div className="bg-grey p-1 w-full h-full rounded">
                <EllipsisVerticalIcon className="size-8 bg-grey" />
              </div>
            }
            placement="bottom-end"
          >
            {moreActionItems.map((item) => (
              <Dropdown.Item key={item.label} onClick={item.onClick}>
                {item.icon}
                <span className="ml-2">{item.label}</span>
              </Dropdown.Item>
            ))}
          </Dropdown>
        )}
      </div>
    </div>
  );
}
