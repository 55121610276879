import { FlowbiteDrawerTheme } from 'flowbite-react';
import { DeepPartial } from 'react-hook-form';

const drawer: DeepPartial<FlowbiteDrawerTheme> = {
  root: {
    base: '!m-0 fixed z-[900] overflow-y-auto bg-white transition-transform dark:bg-gray-800',
    backdrop: '!m-0 fixed inset-0 z-30 bg-gray-900/50 dark:bg-gray-900/80',
    position: {
      // @ts-ignore
      fullDrawer: {
        on: 'h-full bottom-0 left-0 right-0 w-full transform',
        off: 'bottom-0 left-0 right-0 translate-y-full',
      },
    },
  },
  header: {
    inner: {
      closeButton:
        'm-4 absolute end-2.5 top-2.5 flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white',
      closeIcon: 'm-4 h-4 w-4',
      titleIcon: 'me-2.5 h-4 w-4',
      titleText:
        'm-4 inline-flex items-center text-base font-semibold text-gray-500 dark:text-gray-400',
    },
  },
  items: {
    base: 'w-full h-[92%] rounded-none',
  },
};

export default drawer;
