import {
  Marker as GMarker,
  InfoWindow,
  MarkerProps,
} from '@react-google-maps/api';
import { useState } from 'react';
import { AddressOrCoordinate } from '~/generated/models/AddressOrCoordinate.ts';
import { PinColors, pinColors } from './colors';

type Props = Omit<MarkerProps, 'position'> & {
  color?: PinColors;
  address?: AddressOrCoordinate;
  secret?: boolean;
  onClick?: () => void;
};

function Marker({ onClick, color, icon, secret, address, ...props }: Props) {
  const [showInfo, setShowInfo] = useState(false);
  const { fillColor, strokeColor } = color ? pinColors[color] : {};

  return (
    <GMarker
      position={{ lat: address?.latitude ?? 0, lng: address?.longitude ?? 0 }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      icon={
        fillColor || strokeColor
          ? {
              path: 'M 0,0 C -2,-5 -7,-10 -7,-15 A 7,7 0 1,1 7,-15 C 7,-10 2,-5 0,0 z M 0,-13 a 3,3 0 1,1 0.01,0',
              fillColor,
              fillOpacity: 1,
              strokeColor,
              strokeWeight: 2,
              scale: 1.5,
            }
          : undefined
      }
      {...props}
    >
      {!secret && showInfo && address && (
        <InfoWindow onCloseClick={() => setShowInfo(false)}>
          <>
            <strong>{address.name}</strong> - {address.street1}, {address.city},{' '}
            {address.state} {address.code}
          </>
        </InfoWindow>
      )}
    </GMarker>
  );
}

Marker.displayName = 'Marker';

export default Marker;
