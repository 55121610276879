import { UserId } from '@cg/common/src/ids';
import { getDevice } from '@cg/common/src/core';
import AsyncStorage from '@react-native-async-storage/async-storage';

let storage: any;

if (getDevice() === 'Browser') {
  // it will use Localstorage if we are on Web
  storage = {
    getItem: (key: string) => localStorage.getItem(key),
    setItem: (key: string, value: string) => localStorage.setItem(key, value),
    removeItem: (key: string) => localStorage.removeItem(key),
  };
} else {
  storage = AsyncStorage;
}

const PREFIX = '@@cg-token-management@@';
const LAST_LOGGED = `${PREFIX}/last-logged`;

// General factory function for storing data
const factory = <T extends Object>(
  key: string,
  override = {
    setter: (item: T) => item.toString(),
    getter: (item: string | null) => item as T | null,
  },
) => {
  const obj = {
    get: async (): Promise<T | null> => {
      const value = await storage.getItem(key);
      return override.getter(value);
    },
    set: async (item: T) => {
      await storage.setItem(key, override.setter(item));
    },
    clear: async () => {
      await storage.removeItem(key);
    },
    has: async () => {
      const value = await obj.get();
      return value !== null && !!value;
    },
  };

  return obj;
};

const lastLoggedStorage = () => {
  return {
    get: async () => {
      return storage.getItem(LAST_LOGGED);
    },
    set: async (date: Date) => {
      await storage.setItem(LAST_LOGGED, date.toUTCString());
    },
  };
};
export const lastLogged = lastLoggedStorage();

const ACCESS_TOKEN = `${PREFIX}/access-token`;
export const accessToken = factory<string>(ACCESS_TOKEN);

const TICKET_PURCHASER = `${PREFIX}/ticket-purchaser`;
export const ticketPurchaser = factory<UserId>(TICKET_PURCHASER, {
  setter: (item) => item.toString(),
  getter: (item) => {
    if (!item) {
      return null;
    }
    return new UserId(item);
  },
});
