/* eslint-disable */


import { useHttp } from '@cg/frontend-core';

import { createVenue, CreateVenueArgs, deleteVenue, DeleteVenueArgs, fetchAddress, FetchAddressArgs, fetchVenue, FetchVenueArgs, listVenues, ListVenuesArgs, updateAddress, UpdateAddressArgs, updateVenue, UpdateVenueArgs, uploadPhoto, UploadPhotoArgs } from './BackgroundVenues.client';

export const createVenueHook = (immediateCall: boolean = true, initArgs?: CreateVenueArgs) => {
  return useHttp(createVenue, { immediateCall, initArgs })
};
export const deleteVenueHook = (immediateCall: boolean = true, initArgs?: DeleteVenueArgs) => {
  return useHttp(deleteVenue, { immediateCall, initArgs })
};
export const fetchAddressHook = (immediateCall: boolean = true, initArgs?: FetchAddressArgs) => {
  return useHttp(fetchAddress, { immediateCall, initArgs })
};
export const fetchVenueHook = (immediateCall: boolean = true, initArgs?: FetchVenueArgs) => {
  return useHttp(fetchVenue, { immediateCall, initArgs })
};
export const listVenuesHook = (immediateCall: boolean = true, initArgs?: ListVenuesArgs) => {
  return useHttp(listVenues, { immediateCall, initArgs })
};
export const updateAddressHook = (immediateCall: boolean = true, initArgs?: UpdateAddressArgs) => {
  return useHttp(updateAddress, { immediateCall, initArgs })
};
export const updateVenueHook = (immediateCall: boolean = true, initArgs?: UpdateVenueArgs) => {
  return useHttp(updateVenue, { immediateCall, initArgs })
};
export const uploadPhotoHook = (immediateCall: boolean = true, initArgs?: UploadPhotoArgs) => {
  return useHttp(uploadPhoto, { immediateCall, initArgs })
};
