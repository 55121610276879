import { useMemo } from 'react';
import { AddressOrCoordinate } from '~/generated/models/AddressOrCoordinate';

type AddressOptions = {
  shortHanded?: boolean;
  includeName?: boolean;
};

export const getAddressString = (
  address?: AddressOrCoordinate | null,
  options?: AddressOptions,
) => {
  if (!address) {
    return 'Add address 📍';
  }

  if (!address?.street1) {
    return 'Vancouver, BC';
  }

  const prefix = address.street2 ? `#${address.street2} - ` : '';
  const str: string[] = [];

  const name =
    options?.includeName && address.name?.trim() ? address.name.trim() : '';

  if (prefix.trim()) {
    str.push(prefix.trim());
  }
  if (address.street1.trim()) {
    const street1 = address.street1.trim();
    str.push(
      options?.shortHanded
        ? street1.replace('Avenue', 'Ave').replace('Street', 'St')
        : street1,
    );
  }
  if (options?.shortHanded) {
    const joined = str.join(', ');
    if (name) {
      return `${name} – ${joined}`;
    }
    return joined;
  }

  if (address.city?.trim()) {
    str.push(address.city.trim());
  }

  if (address.state?.trim() || address.code?.trim()) {
    str.push(`${address.state?.trim()} ${address.code?.trim()}`);
  }
  if (address.country?.trim()) {
    str.push(address.country?.trim());
  }

  return str.join(',');
};

export const useAddressString = (
  isSecret: boolean,
  address?: AddressOrCoordinate | null,
  options?: AddressOptions,
) => {
  return useMemo(() => {
    if (isSecret) {
      return 'Location is secret for now 🤫. It will be revealed on the day of the event.';
    }

    return getAddressString(address, options);
  }, [address, isSecret]);
};
