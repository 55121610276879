import { FileUpload, Button, Img } from '@cg/module-frontend/src/components';
import { ImageIcon } from '@cg/module-frontend/src/icons';
import classNames from 'classnames';
import React from 'react';
import { VenueId } from '@cg/common/src/ids';
import { EmptyBanner } from '@cg/module-frontend/src/components/forms';
import { useLogger } from '@cg/frontend-core';

type NewType = {
  venueId: VenueId;
  getValues: (key: string) => string;
  setValue: (key: string) => void;
  className: string;
};

type UploaderProps = NewType;

function Uploader({ venueId, getValues, setValue, className }: UploaderProps) {
  const logger = useLogger('Uploader');
  const [isUploading, setIsUploading] = React.useState(false);
  const [isUploadHovered, setIsUploadHovered] = React.useState(false);

  return (
    <FileUpload
      minWidth={720}
      minHeight={720}
      maxSize={5}
      ids={{ venueId }}
      type="Venue"
      onUploading={() => setIsUploading(true)}
      onUploaded={(response) => {
        setIsUploading(false);
        setValue(response.imageUrl);
      }}
      onUploadFailed={() => logger.error('upload failed')}
      className={classNames(
        'w-full h-full flex aspect-square relative',
        className,
      )}
    >
      {({ openFileSelector }) => {
        return getValues('bannerUrl') ? (
          <div
            className={classNames('flex items-end justify-end w-full h-full', {
              'animate-[pulse_1.2s_infinite]': isUploading,
            })}
          >
            <Img
              src={getValues('bannerUrl')}
              alt="Venue Splash"
              className="object-cover w-full h-full"
            />
            <div
              role="presentation"
              className="absolute m-6 w-8 h-8"
              onMouseEnter={() => setIsUploadHovered(true)}
              onMouseLeave={() => setIsUploadHovered(false)}
            >
              <div
                className={classNames(
                  'transition-opacity duration-700 ease-in-out absolute inset-0',
                  {
                    'opacity-0': isUploadHovered,
                    'opacity-100': !isUploadHovered,
                  },
                )}
              >
                <ImageIcon className="size-11 stroke-primary text-primary flex z-30 p-2 bg-secondary opacity-70 hover:opacity-95 rounded" />
              </div>
              <div
                className={classNames(
                  'transition-transform transform origin-right duration-300 ease-out absolute right-0 -top-1 flex items-center bg-secondary opacity-95 p-1 gap-2 rounded text-sm',
                  {
                    'scale-x-100 opacity-100': isUploadHovered,
                    'scale-x-0 opacity-0': !isUploadHovered,
                  },
                )}
              >
                <Button
                  color="primary"
                  size="xs"
                  className="h-fit"
                  onClick={openFileSelector}
                >
                  Replace
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <EmptyBanner
            openFileSelector={openFileSelector}
            primary={
              <>
                <span className="font-bold">Click to upload</span> a splash
                image for the Venue.
              </>
            }
            secondary={
              <span className="text-xs dark:text-grey-darker">
                SVG, PNG or JPG
              </span>
            }
          />
        );
      }}
    </FileUpload>
  );
}

export default Uploader;
