import log from 'loglevel';
import logFormatter from 'loglevel-plugin-prefix';
import { getDevice } from '@cg/common/src/core';
import { LoggerTransport } from './types';

const getWebRollbar = async (): Promise<LoggerTransport> => {
  const { default: WebRollbarTransport } = await import('./rollbar.web');
  return new WebRollbarTransport();
};

const getMobileRollbar = async (): Promise<LoggerTransport> => {
  const { default: MobileRollbarTransport } = await import('./rollbar.mobile');
  return new MobileRollbarTransport();
};

export default class Logger {
  private readonly logger: log.Logger;

  private transports: LoggerTransport[] = [];

  constructor(loggerName = 'Global', transports: LoggerTransport[] = []) {
    this.logger = log.getLogger(loggerName);

    this.logger.setLevel('info');

    logFormatter.reg(log);
    logFormatter.apply(this.logger, {
      format(level, name, timestamp) {
        return `[${name} - ${timestamp}] ${level.toUpperCase()}`;
      },
    });

    this.transports = transports;
    this.initializeTransports();
  }

  /**
   * Initializes transports
   * @private
   */
  private async initializeTransports() {
    if (getDevice() === 'Mobile') {
      this.transports.push(await getMobileRollbar());
    } else {
      this.transports.push(await getWebRollbar());
    }
  }

  /**
   * Formats message with placeholders (%s, %d)
   */
  private formatMessage(message: string, args: any[]): string {
    return args.length > 0
      ? message.replace(/%s|%d/g, () => args.shift())
      : message;
  }

  /**
   * Logs a message with a specific level
   */
  log(
    level: 'info' | 'warn' | 'error' | 'debug',
    message: string,
    ...args: unknown[]
  ) {
    const formattedMessage = this.formatMessage(message, args);
    this.logger[level](formattedMessage);

    // Send logs to all transports
    this.transports.forEach((transport) => {
      transport.log(level, formattedMessage, ...args);
    });
  }

  info(message: string, ...args: unknown[]) {
    this.log('info', message, ...args);
  }

  warn(message: string, ...args: unknown[]) {
    this.log('warn', message, ...args);
  }

  error(message: string, ...args: unknown[]) {
    this.log('error', message, ...args);
  }

  debug(message: string, ...args: unknown[]) {
    this.log('debug', message, ...args);
  }
}
