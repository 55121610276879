/* eslint-disable */


import { http, HttpResult } from '@cg/frontend-core';

import { Ticket } from '../../../models/Ticket';
import { TicketId } from '@cg/common/src/ids/TicketId';
import { TicketTier } from '../../../models/TicketTier';
import { TicketTierId } from '@cg/common/src/ids/TicketTierId';
import { RefundTicketRequest } from '../../../models/RefundTicketRequest';

export type FetchTicketArgs = {
    verifiedUser?: boolean;
    ids: {
        ticketId: TicketId;
    };
}

/**
 * @summary Fetches the Ticket
 * @param {FetchTicketArgs} args
 */
export const fetchTicket = (args: FetchTicketArgs): Promise<HttpResult<Ticket>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/tickets/{ticketId}', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchTicketTierArgs = {
    verifiedUser?: boolean;
    ids: {
        ticketTierId: TicketTierId;
    };
}

/**
 * @summary Returns a Ticket Tier
 * @param {FetchTicketTierArgs} args
 */
export const fetchTicketTier = (args: FetchTicketTierArgs): Promise<HttpResult<TicketTier>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/tiers/{ticketTierId}', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type RefundTicketArgs = {
    verifiedUser?: boolean;
    ids: {
        ticketId: TicketId;
    };
      body: RefundTicketRequest;
}

/**
 * @summary Refunds a Ticket
 * @param {RefundTicketArgs} args
 */
export const refundTicket = (args: RefundTicketArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/tickets/{ticketId}/refund', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
